.label {
  color: #2a4084;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.4375em;
  font-family: 'Inter',sans-serif;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  margin: 8px 8px 0 18px;
  padding: 0 0 0 4px;
  z-index: 9999;
  background-color: #fdfdfd;
}
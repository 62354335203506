@import "../../assets/scss/themes-vars.module";
.title {
  margin: 0;
  font-size: 1.125rem;
  color: #212121;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  line-height: 1.334;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin iconClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 50px;
  cursor: pointer;
}

.iconClose {
  @include iconClose;
  background-color: transparent;
}

.iconClose:hover {
  @include iconClose;
  background-color: $grey200;
}
// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

@mixin button {
  border-radius: 8px;
  height: 34px;
}

.buttonPrimary {
  @include button;
  color: $secondaryDark;
  background-color: $secondaryLight;
}

.buttonPrimary:hover {
  @include button;
  color: $secondaryLight;
  background-color: $secondaryDark;
}

.buttonPrimary > div > svg {
  stroke: $secondaryDark;
}

.buttonPrimary:hover > div > svg {
  stroke: $secondaryLight;
}

.buttonSuccess {
  @include button;
  color: $successDark;
  background-color: $successLight;
}

.buttonSuccess:hover {
  @include button;
  color: white;
  background-color: $successDark;
}

.buttonSuccess > div > svg {
  stroke: $successDark;
}

.buttonSuccess:hover > div > svg {
  stroke: white;
}


.buttonDanger {
  @include button;
  color: $errorDark;
  background-color: $errorExtraLight;
}

.buttonDanger:hover {
  @include button;
  color: $secondaryLight;
  background-color: $errorDark;
}

.buttonDanger > div > svg {
  stroke: $errorDark;
}

.buttonDanger:hover > div > svg {
  stroke: $secondaryLight;
}


.buttonSecondary {
  @include button;
  border: solid 1px $secondaryDark;
  color: $secondaryDark;
  background-color: white;
}

.buttonSecondary:hover {
  @include button;
  border: solid 1px $secondaryDark;
  color: white;
  background-color: $secondaryDark;
}

.buttonSecondary > div > svg {
  stroke: $secondaryDark;
}

.buttonSecondary:hover > div > svg {
  stroke: white;
}

.Mui-disabled > div > svg {
  stroke: rgba(1, 1, 1, 0.20);
}

button > div > svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5px;
}

.Mui-disabled.buttonSecondary {
  border: 1px solid lightgrey;
}

.MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.5);
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl {
  opacity: 0.6 !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl:not(.Mui-disabled):hover {
  opacity: 0.6 !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.MuiDialogContent-root {
  padding: 16px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $secondaryMain !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $secondaryMain !important;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked {
  color: $secondaryMain;
}

.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Mui-disabled {
   color: rgba(0, 0, 0, 0.26);
 }
